import React from "react";
import classes from "../styles/photos.module.css";
import Layout from "../components/Layout";
import { StylesProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Img from "gatsby-image/withIEPolyfill";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const title = "Photos | Avenue Barber";
const description = "Check out our photo gallery.";

export default function Photos({ data, location }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        {/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}

        {/* <meta property="og:url" content="PERMALINK" /> */}

        <meta property="og:site_name" content="Avenue Barber" />
        <link rel="canonical" href="https://avenuebarber.ca/photos" />
      </Helmet>
      <StylesProvider injectFirst>
        <Layout data={data} location={location}>
          <div className={classes.imgContainer}>
            <AutoPlaySwipeableViews
              // index={activeStep}
              // onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage2.childImageSharp.fluid}
              />
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage3.childImageSharp.fluid}
              />
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage4.childImageSharp.fluid}
              />
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage6.childImageSharp.fluid}
              />
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage7.childImageSharp.fluid}
              />
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage8.childImageSharp.fluid}
              />
              <Img
                className={classes.desktopImage}
                fluid={data.desktopCoverImage9.childImageSharp.fluid}
              />
            </AutoPlaySwipeableViews>
          </div>
        </Layout>
      </StylesProvider>
    </>
  );
}

export const query = graphql`
  query {
    desktopCoverImage2: file(relativePath: { eq: "home-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage3: file(relativePath: { eq: "home-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage4: file(relativePath: { eq: "home-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage5: file(relativePath: { eq: "home-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage6: file(relativePath: { eq: "home-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage7: file(relativePath: { eq: "home-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage8: file(relativePath: { eq: "home-8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopCoverImage9: file(relativePath: { eq: "home-9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mobileLogo: file(relativePath: { eq: "logo-mobile.png" }) {
      childImageSharp {
        fixed(width: 96, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "logo-desktop.png" }) {
      childImageSharp {
        fixed(width: 256, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;
